<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-machine' }">{{
            $t("MENU.ITEM.ASSETS.MACHINES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingMachine"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { SAVE_MACHINE } from "@/modules/machines/store/machine.module";
import { FETCH_MACHINES_TYPES } from "@/modules/machines-types/store/machinetype.module";
import { FETCH_FUELS_TYPES } from "@/modules/fuels-types/store/fuel-type.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      machines_types: [],
      fuel_type: [],
      staff: []
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ASSETS.MACHINES"),
        route: { name: "list-machine" }
      },
      { title: this.$i18n.t("MENU.ITEM.ADD") }
    ]);

    this.$store.dispatch(FETCH_FUELS_TYPES).then(data => {
      this.fuel_type = data.data.items;
    });

    this.$store
      .dispatch(
        FETCH_MACHINES_TYPES,
        vm.$url.transformParams({ onlySelectValues: true })
      )
      .then(data => {
        this.machines_types = data.data.items;
        vm.generateFormOutOfSchemaJson(this.schemaJson);
      });

    this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then(data => {
      this.staff = data.data.items;
    });

    vm.generateFormOutOfSchemaJson(this.schemaJson);
  },
  computed: {
    ...mapGetters(["isLoadingMachine", "isLoadingSharedStore"]),
    ...mapState({
      errors: state => state.auth.errors
    }),

    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.REGISTRATION_PLATE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_REGISTRATION_PLATE_SHORT",
              validation: "FORM.VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "registrationPlate"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            colSm: "3",
            feedback_id: "unit-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_TYPE"
            },
            options: this.machines_types,
            validations: {
              required: true
            },
            model: "machineTypeId"
          },
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            divider: false,
            i18n: {
              label: "FORM_LABELS.TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_TYPE",
              validation: "FORM.VALID_TYPE"
            },
            validations: {},
            translatable: false,
            model: "machineName"
          },
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_MODEL",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_MODEL",
              validation: "FORM.VALID_MACHINE_MODEL"
            },
            validations: {},
            translatable: false,
            model: "machineModel"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "3",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_FUEL_TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_FUEL_TYPE"
            },
            options: this.fuel_type,
            validations: {
              required: true
            },
            model: "fuelTypeId"
          },
          {
            type: "input",
            inputType: "number",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.TANK_SIZE",
              placeholder: "FORM_PLACEHOLDERS.TANK_SIZE",
              validation: "FORM.VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "tankSize"
          },
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.GPS_TRACKER_NUMBER",
              placeholder: "FORM_PLACEHOLDERS.GPS_TRACKER_NUMBER",
              validation: "FORM.VALID_NAME"
            },
            validations: {},
            translatable: false,
            model: "gpsTrackerNumber"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-staff-group",
            id: "item-staff-input",
            feedback_id: "staff-live-feedback",
            colSm: "3",
            divider: false,
            clearable: false,
            i18n: {
              label: "MENU.ITEM.STAFF.STAFF",
              placeholder: "MENU.ITEM.STAFF.STAFF"
            },
            options: this.staff,
            validations: {
              required: true
            },
            model: "lastUsedStaffId"
          },
          {
            type: "switch",
            groupId: "staff-active-group",
            id: "staff-active-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "active-live-feedback",
            colSm: "auto",
            divider: true,
            default: true,
            i18n: {
              label: "FORM_LABELS.ACTIVE",
              placeholder: "FORM_PLACEHOLDERS.ACTIVE",
              validation: "FORM.VALID_ACTIVE"
            },
            validations: {
              required: true
            },
            model: "active"
          },
          {
            type: "switch",
            groupId: "staff-no-mileage-group",
            id: "staff-no-mileage-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "no-mileage-live-feedback",
            colSm: "auto",
            divider: true,
            default: false,
            i18n: {
              label: "FORM_LABELS.NO_MILEAGE",
              placeholder: "FORM_PLACEHOLDERS.NO_MILEAGE",
              validation: "FORM.VALID_NO_MILEAGE"
            },
            validations: {
              required: true
            },
            model: "noMileage"
          },
          {
            type: "switch",
            groupId: "staff-is-assignable-group",
            id: "staff-is-assignable-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "is-assignable-live-feedback",
            colSm: "auto",
            divider: true,
            default: true,
            i18n: {
              label: "FORM_LABELS.IS_ASSIGNABLE",
              placeholder: "FORM_PLACEHOLDERS.IS_ASSIGNABLE",
              validation: "FORM.VALID_IS_ASSIGNABLE"
            },
            validations: {
              required: true
            },
            model: "isAssignable"
          }
        ]
      };
    }
  },
  validations() {
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSave(next) {
      let vm = this;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_MACHINE, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-machine",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-machine" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-machine" });
          }
        })
        .catch(response => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    }
  }
};
</script>
